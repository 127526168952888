export const API_BASE_URL = "https://marketplace-dev-api.junona.net/api/";

export const NetworkContextName = "NETWORK";

export const TOAST_AUTO_CLOSE_TIME = 3000;

export enum ApiRoutes {
  CategoryList = "contracts/category/",
  CodeList = "contracts/code/",
  ContractList = "contracts/contract/",
  TextContractList = "contracts/text_contract/",
  JurisdictionList = "contracts/jurisdiction",
}

export enum AppRoutes {
  Contracts = "/contracts",
  Home = "/home",
  Catalog = "/catalog",
  ContractTemplate = "/template-contract",
  DeployContract = "/deploy-contract",
  JunaSimple = "/juna-simple",
}
