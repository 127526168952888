import { VFC, Fragment, useState, useCallback, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { ADD_ICON, CHECK_MARK_ICON } from "constants/images";
import { useToast } from "hooks";
import { Shareholder, InputName } from "../../types";
import AddBlock from "../AddBlock";
import {
  Wrapper,
  FlexWrapper,
  Label,
  Overflow,
  ShareholderLabel,
  Line,
  ShareholderInput,
  StyledEditBtn,
  RemoveBtn,
} from "./style";

type Props = {
  onChange: (shareholder: Shareholder[]) => void;
  onModeChange: () => void;
  shareholders: Shareholder[];
  totalShare: number;
};

type ShareholderInputState = Record<keyof Shareholder, string>;

const Table: VFC<Props> = (props) => {
  const { onChange, onModeChange, shareholders, totalShare } = props;

  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);

  const [shareholderInputValue, setShareholderInputValue] =
    useState<ShareholderInputState>({ address: "", share: "" });

  const { t } = useTranslation();

  const toast = useToast();

  const toggleMode = useCallback(() => {
    setIsCreateMode((prev) => !prev);
    onModeChange();
  }, [onModeChange]);

  const validateShareholder = useCallback(
    ({ address, share }: ShareholderInputState): string | null => {
      if (!address || !share) {
        return t("errors.emptyFields");
      }

      const shareNumber = Number.parseFloat(share);

      if (Number.isNaN(shareNumber) || shareNumber <= 0) {
        return t("errors.lessZero");
      }

      const actualTotalShare = totalShare + shareNumber;

      if (actualTotalShare > 100) {
        return t("errors.moreHundred");
      }

      const isSameShareholder = shareholders.some(
        (shareholder) => shareholder.address === address
      );

      if (isSameShareholder) {
        return t("errors.sameShareholders");
      }

      return null;
    },
    [shareholders, t, totalShare]
  );

  const handleCreate = useCallback(() => {
    const validationError = validateShareholder(shareholderInputValue);

    if (validationError) {
      toast.error({
        title: "Error",
        message: validationError,
      });
      return;
    }

    const { address, share } = shareholderInputValue;

    const normalizedShare = Math.round(Number.parseFloat(share) * 100) / 100;

    onChange([...shareholders, { address, share: normalizedShare }]);

    setShareholderInputValue({ address: "", share: "" });

    toggleMode();
  }, [
    onChange,
    shareholderInputValue,
    shareholders,
    toast,
    toggleMode,
    validateShareholder,
  ]);

  const handleShareholderChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = evt;

    setShareholderInputValue({
      ...shareholderInputValue,
      [name]: value,
    });
  };

  const removeShareholder = useCallback(
    (address: string) => {
      onChange(
        shareholders.filter(
          (shareholder: Shareholder) => shareholder.address !== address
        )
      );
    },
    [onChange, shareholders]
  );

  return (
    <>
      <Wrapper>
        <FlexWrapper>
          <Label>{t("junaSimple.deployPage.shareholders")}</Label>
          <StyledEditBtn
            color="gray"
            onClick={isCreateMode ? handleCreate : toggleMode}
          >
            <img alt="#" src={isCreateMode ? CHECK_MARK_ICON : ADD_ICON} />
          </StyledEditBtn>
        </FlexWrapper>
        <Overflow>
          {shareholders.map(({ address, share }) => (
            <Fragment key={address}>
              <Line />
              <FlexWrapper>
                <ShareholderLabel>{address}</ShareholderLabel>
                <FlexWrapper>
                  <ShareholderLabel>{share}%</ShareholderLabel>
                  <RemoveBtn onClick={() => removeShareholder(address)}>
                    Delete
                  </RemoveBtn>
                </FlexWrapper>
              </FlexWrapper>
            </Fragment>
          ))}
        </Overflow>
        {isCreateMode && (
          <FlexWrapper>
            <ShareholderInput
              name={InputName.Address}
              onChange={handleShareholderChange}
              placeholder={t("junaSimple.deployPage.placeholder")}
              value={shareholderInputValue.address}
            />
            <ShareholderInput
              max="100"
              min="0"
              name={InputName.Share}
              onChange={handleShareholderChange}
              placeholder="Add share"
              step="0.01"
              type="number"
              value={shareholderInputValue.share}
            />
          </FlexWrapper>
        )}
      </Wrapper>
      {shareholders.length === 0 && !isCreateMode && (
        <AddBlock handleClick={toggleMode} />
      )}
    </>
  );
};

export default Table;
