import { VFC, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { AxiosError } from "axios";
import { ethers } from "ethers";
import { FormikConfig, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router";
import { api } from "api";
import Breadcrumbs from "components/Breadcrumbs";
import Container from "components/Container";
import GuardedButton from "components/GuardedButton";
import Loader from "components/Loader";
import Select from "components/Select";
import { AppRoutes } from "constants/index";
import { useAuth, useToast } from "hooks";
import { Api } from "typings";
import ContractInput from "../Contract/components/ContractInput";
import Description from "./components/Description";
import {
  Title,
  Case,
  Items,
  Item,
  Label,
  BtnWrapper,
  InputsWrapper,
} from "./style";

type FormValues = {
  abi: string;
  audit: string;
  bytecode: string;
  category?: Api.Category;
  code: string;
  description: string;
  ipfs: string;
  jurisdiction?: Api.Jurisdiction;
  name: string;
};

/**
 * Contract Create Page - smart contract creation / editing page
 *
 * @returns - TSX element (Contract Create page)
 */

export const ContractCreate: VFC = () => {
  const { t } = useTranslation();

  const { account } = useAuth();

  const { contractId }: { contractId: string } = useParams();

  const history = useHistory();

  const web3 = useWeb3React();

  const toast = useToast();

  const isUpdate = Boolean(contractId);

  const { data: jurisdictions, isLoading: isJurisdictionsLoading } = useQuery(
    "jurisdictions",
    api.getJurisdictions
  );

  const { data: categories, isLoading: isCategoryLoading } = useQuery(
    "categories",
    api.getCategories
  );

  const { data: contract, isLoading: isContractLoading } = useQuery(
    ["editableContract", contractId],
    () => api.getContractById(Number(contractId)),
    {
      onError: (err: AxiosError) => {
        return toast.error({
          message: err.message,
          title: err.name,
        });
      },
      enabled: isUpdate,
    }
  );

  const { mutate } = useMutation(
    (contractData: Api.ContractMutateData) => {
      return isUpdate
        ? api.updateContract(Number(contractId), contractData)
        : api.createContract(contractData);
    },
    {
      onSuccess: ({ id, name }) => {
        toast.success({
          message: `Contract "${name}" successfully ${
            isUpdate ? "updated" : "created"
          }`,
          title: `Contract ${isUpdate ? "updated" : "created"}`,
        });
        history.push(`${AppRoutes.Contracts}/${id}`);
      },
      onError: (err: AxiosError) => {
        return toast.error({
          message: err.message,
          title: err.name,
        });
      },
    }
  );

  const initialValues = {
    abi: contract?.code?.abi || "",
    audit: contract?.code?.audit || "",
    bytecode: contract?.code?.bytecode || "",
    category: contract?.category,
    code: contract?.code?.code || "",
    description: contract?.description || "",
    ipfs: contract?.textContract?.ipfs || "",
    jurisdiction: contract?.jurisdiction,
    name: contract?.name || "",
  };

  const handleSubmit = useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values) => {
      const provider = new ethers.providers.Web3Provider(web3.library.provider);
      const signedMsg = await provider
        .getSigner()
        .signMessage(account as string);

      mutate({
        name: values.name,
        category: Number(values.category!.id),
        jurisdiction: Number(values.jurisdiction!.id),
        description: values.description,
        owner: account as string,
        code: {
          abi: values.abi,
          audit: values.audit,
          bytecode: values.bytecode,
          code: values.code,
        },
        textContract: {
          ipfs: values.ipfs,
        },
        signedMsg,
      });
    },
    [mutate, account, web3]
  );

  const formik = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  if (isContractLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Breadcrumbs>{t("contractPage.title")}</Breadcrumbs>
      <Title>{t("contractPage.title")}</Title>
      <form onSubmit={formik.handleSubmit}>
        <Case>
          <Items>
            <Item>
              <Label>Name</Label>
              <InputsWrapper>
                <ContractInput
                  name="name"
                  onChange={formik.handleChange}
                  placeholder={t("input")}
                  value={formik.values.name}
                />
              </InputsWrapper>
            </Item>
            <Item>
              <Label>Category</Label>
              <InputsWrapper>
                <Select
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => `${option.id}`}
                  isLoading={isCategoryLoading}
                  onChange={(value) => formik.setFieldValue("category", value)}
                  options={categories}
                  placeholder={t("select.main")}
                  value={formik.values.category}
                />
              </InputsWrapper>
            </Item>
            <Item>
              <Label>Audit</Label>
              <InputsWrapper>
                <ContractInput
                  name="audit"
                  onChange={formik.handleChange}
                  placeholder={t("input")}
                  value={formik.values.audit}
                />
              </InputsWrapper>
            </Item>
          </Items>
          <Items>
            <Item>
              <Label>IPFS link</Label>
              <InputsWrapper>
                <ContractInput
                  name="ipfs"
                  onChange={formik.handleChange}
                  placeholder={t("input")}
                  value={formik.values.ipfs}
                />
              </InputsWrapper>
            </Item>
            <Item>
              <Label>Jurisdiction</Label>
              <InputsWrapper>
                <Select
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => `${option.id}`}
                  isLoading={isJurisdictionsLoading}
                  onChange={(value) =>
                    formik.setFieldValue("jurisdiction", value)
                  }
                  options={jurisdictions}
                  placeholder={t("select.main")}
                  value={formik.values.jurisdiction}
                />
              </InputsWrapper>
            </Item>
          </Items>
        </Case>
        <Description
          label="Code:"
          name="code"
          onChange={formik.handleChange}
          value={formik.values.code}
        />
        <Description
          label="Deployed ByteCode:"
          name="bytecode"
          onChange={formik.handleChange}
          value={formik.values.bytecode}
        />
        <Description
          label="Contract ABI:"
          name="abi"
          onChange={formik.handleChange}
          value={formik.values.abi}
        />
        <Description
          label="Description:"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
        />

        <BtnWrapper>
          <GuardedButton color="gradient" type="submit">
            {isUpdate
              ? t("contractPage.updateBtn")
              : t("contractPage.createBtn")}
          </GuardedButton>
        </BtnWrapper>
      </form>
    </Container>
  );
};
